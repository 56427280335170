<script lang="ts" setup>
const { t } = useT();
const { data: appInitData } = useAppInitData({ immediate: false });
const offer = computed(() => appInitData.value?.popupPresetPackage?.promoOfferPreset ?? {});

const prepareImgUrl = useImage();
const bgImage = `url(${prepareImgUrl("/nuxt-img/home/bg-auth.jpg", { format: "avif" })})`;
const bgArt = `url(${prepareImgUrl("/nuxt-img/home/box-auth.png", { format: "avif" })})`;
const bgArtMob = `url(${prepareImgUrl("/nuxt-img/home/box-auth-mob.png", { format: "avif", width: 720, height: 1134 })})`;
</script>
<template>
	<section class="box-auth">
		<div class="col-offer">
			<div v-if="offer" class="offer">
				<AText v-if="offer?.title" :size="{ full: 40, lg: 22 }" :modifiers="['bold', 'uppercase']" class="title">
					{{ offer?.title }}
				</AText>

				<div v-if="offer?.coins || offer?.entries" class="prize-blocks">
					<MPrizeFund v-if="offer?.coins" variant="coins" icon="20/coins" :icon-size="36">
						<AText class="text-neutral-100" :size="{ full: 36, md: 20 }">
							{{ numberFormat(offer?.coins) }}
						</AText>
					</MPrizeFund>
				</div>

				<AText
					v-if="appInitData?.popupPresetPackage?.money && offer?.usualPrice"
					:size="{ full: 16, lg: 10 }"
					class="box-usual"
				>
					<AText :size="{ full: 24, lg: 14 }" class="spec text-neutral-5" :modifiers="['bold']">
						${{ numberFormat(appInitData?.popupPresetPackage?.money, { minimumFractionDigits: 2 }) }}
					</AText>
					{{ t("was") }}
					<del>${{ numberFormat(offer?.usualPrice, { minimumFractionDigits: 2 }) }}</del>
				</AText>
			</div>
		</div>
		<div class="banner-form flex-center">
			<OSignUpForm class="form" />
		</div>
	</section>
</template>

<style lang="scss" scoped>
.box-auth {
	display: flex;
	justify-content: center;
	min-height: 548px;
	height: 100%;
	background: var(--chaozhou) v-bind(bgImage) no-repeat center right / cover;
	margin: 16px 0;
	gap: 16px;
	position: relative;

	@include media-breakpoint-up(md) {
		justify-content: flex-end;
		border-radius: 12px;
	}
	@include media-breakpoint-down(md) {
		flex-direction: column;
		padding: 16px 0;
		background-position: 88%;
		margin-top: 0px;

		> * {
			position: relative;
			z-index: 1;
		}

		&:after,
		&:before {
			content: "";
			display: flex;
			width: 100%;
			height: 62px;
			opacity: 0.6;
			background: linear-gradient(
				180deg,
				rgba(11, 16, 32, 0) 0%,
				rgba(11, 16, 32, 0.39) 44.97%,
				rgba(11, 16, 32, 0.39) 64.93%,
				#0b1020 100%
			);
			position: absolute;
			bottom: 0;
			left: 0;
		}

		&:after {
			top: 0;
			bottom: auto;
			transform: rotate(180deg);
		}
	}
}

.col-offer {
	display: inline-flex;
	align-items: center;
	padding-left: 32px;

	@include media-breakpoint-down(md) {
		padding: 0 16px;
		margin-bottom: -60px;
	}
}

.box-usual {
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 4px;
}

.banner-form {
	width: 100%;
	background: v-bind(bgArtMob) no-repeat center -22px / 360px 567px;

	@include media-breakpoint-up(md) {
		width: 850px;
		background: v-bind(bgArt) no-repeat -30px center / contain;
	}

	@include media-breakpoint-down(md) {
		padding: 60px 0 50px;
		margin-bottom: -40px;
	}
}

.spec {
	border-radius: 8px;
	background: var(--tertiary-80);
	padding: 4px 8px;

	@include media-breakpoint-down(lg) {
		padding: 2px 4px;
	}
}

.prize-blocks {
	display: flex;
	flex-direction: column;

	@include media-breakpoint-down(lg) {
		span,
		:deep(.icon) {
			font-size: 20px;
		}
	}

	@include media-breakpoint-down(md) {
		flex-direction: row;
		gap: 16px;
	}
}

.offer {
	width: 400px;
	display: inline-flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 8px;

	@include media-breakpoint-down(lg) {
		width: 200px;
	}
	@include media-breakpoint-down(md) {
		width: 100%;
		align-items: center;
		gap: 2px;
	}
}

.form {
	background: var(--neutral-15);
	color: var(--neutral-100);
	width: 328px;
	padding: 16px;
	border-radius: 12px;
	z-index: 1;
}
</style>
